<template>
  <b-button
    v-if="checkRequiredPermissions([$roles.MIGRATION_EXPORT_USE])"
    :variant="variant"
    :size="size"
    :disabled="loading"
    @click="processExport"
  >
    <span>
      <feather-icon icon="DownloadIcon" />
      <span v-if="showLabel">Export</span>
    </span>
  </b-button>
</template>

<script>
import axiosIns from '@/libs/axios'
import {
  contactMigration,
  contactThreadProcessesMigration,
  noteMigration,
  orderMigration,
  tasksMigration,
} from '@/@data/migrations'

export default {
  name: 'ExportButton',
  props: {
    size: { required: false, default: 'sm', type: String },
    variant: { required: false, default: 'primary', type: String },
    showLabel: { required: false, default: true, type: Boolean },

    dataType: {
      required: true, default: 'CONTACT', type: String, enum: ['CONTACT', 'CONTACT_THREAD', 'CONTACT_THREAD_TASK', 'CONTACT_THREAD_NOTE', 'CONTACT_THREAD_CART_ORDER'],
    },

    filtersParams: {
      default: null,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async processExport() {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('ExportByFilters'))
      if (!confirmed) return

      let scope = null

      // Filters DONE
      if (this.dataType === 'CONTACT') {
        scope = contactMigration(this.filtersParams)
      }

      // Filters DONE
      if (this.dataType === 'CONTACT_THREAD_TASK') {
        scope = tasksMigration(this.filtersParams)
      }

      // Filters DONE
      if (this.dataType === 'CONTACT_THREAD_NOTE') {
        scope = noteMigration(this.filtersParams)
      }

      if (this.dataType === 'CONTACT_THREAD') {
        scope = contactThreadProcessesMigration(this.filtersParams)
      }

      // Filters DONE
      if (this.dataType === 'CONTACT_THREAD_CART_ORDER') {
        scope = orderMigration(this.filtersParams)
      }

      const payload = {
        dataType: this.dataType,
        dataScope: scope,
        type: 'EXPORT',
      }

      await axiosIns.post('1/migrations', payload)

      try {
        this.loading = true
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
